.progone,
.progtwo,
.progthree {
  font-family: "Courier New", Courier, monospace;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  margin-left: 3%;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.prog {
  border: 1px solid orange;
  width: 450px;
  display: flex;
}
main {
  display: flex;
  margin: 5%;
}
span {
  margin: 0;
  padding: 0;
  color: red;
}
.desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seemore {
  border: 1px solid orange;
  background-color: orange;
  border-radius: 10px 10px;

  text-align: center;
}
.seemore:hover {
  border: 1px solid orange;
  background-color: black;
  border-radius: 10px 10px;
  color: white;
}
.slidercont {
  margin-top: 60px;
}

.imgslidercont {
  height: 50%;
}
@media screen and (max-width: 1500px) {
  main {
    display: flex;
    margin: 5%;
  }
  .prog {
    border: 1px solid orange;
    width: 460px;
    display: flex;

  }
  .mainbody {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
  }
.progone,
.progtwo,
.progthree {
  display: flex;
  flex-direction: column;
  font-family: "Courier New", Courier, monospace;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  justify-content: space-between;
  margin-left: 3%;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
}


@media screen and (max-width: 573px) {
  main {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
  }
  .prog {
    width: auto;
    margin-bottom: 5%;
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: none;
    box-shadow: none;
    backdrop-filter: none;
  }
  .mainbody {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
  }
}



