@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed&display=swap);
body {
  margin: 0;
  padding: 0;
  padding: 0;
  position: relative;
}

.mainbody {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}

img {
  display: block;
  height: 100%;
  width: 100%;
}

.humbermenuicon {
  display: none;
}
.navigation {
  display: block;
}
.selected {
  outline: none;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: orangered 1px solid;
}
.slogan {
  font-family: "Fira Sans Extra Condensed", sans-serif;
}
a {
  text-decoration: none;
  color: red;
  text-align: left;
}
.logoimg {
  width: 390px;
}
.logoimg > img {
  width: 100%;
  height: 100%;
}
.logoandslogongroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 573px) {
  .navigation {
    display: none;
  }
  .active {
    background: rgba(255, 44, 44, 0.767);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 2;

    -webkit-animation: rotate-bottom 0.5s ease-out both;
    animation: rotate-bottom 0.5s ease-out both;
    display: flex;
    overflow: scroll;
    position: fixed;
    right: 25px;
    width: 25%;
    height: 65vh;
    border-radius: 43px 43px 43px 32px / 32px 32px 32px 43px;
  }
  .active > .navbar > a {
    color: white;
  }
  .active > .navbar {
    margin: 0;
    padding: 0;

    border: none;
    display: flex;

    flex-direction: column;
  }
  .humbermenuicon {
    border-radius: 50% 50%;
    border-color: black;
    background-color: red;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    color: whitesmoke;
    justify-content: center;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 4;
  }
  .selected {
    outline: none;
    border-bottom: 1px solid tomato;

    text-align: left;
    display: block;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-7-27 12:52:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation rotate-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes rotate-bottom {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
  }
  @keyframes rotate-bottom {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
  }
}

footer {
  width: 50%;
  margin-left: 30%;
  /* border: 1px solid black; */
  text-align: center;
}
.track{
  border:1px solid orange;

}
.nextButton {
  color:red;
}
.slide {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
}
.slider {
  height: 200px;
  
  /* background: rgba(220, 199, 199, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */
}
.descriptions2 {
  display: -ms-flexbox;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .sliderimage {
  width: 100%;
  height: 100%;
} */
.sliderimage {
  width: 180px;
  height: auto;
  align-self: center;
  justify-self: center;
}
.sliderimage > img {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 573px) {
  footer {
    width: 100%;
    margin: 0%;
  }
  .sliderimage {
    width: 60%;
    height: 70%;
    border-radius: 50% 50%;
  }
  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
  }
  .slidertitle {
    order:1;
  }
  .descriptions2 {
    order: 2;
  }
}

.progone,
.progtwo,
.progthree {
  font-family: "Courier New", Courier, monospace;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(18px);
          backdrop-filter: blur(18px);
  margin-left: 3%;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.prog {
  border: 1px solid orange;
  width: 450px;
  display: flex;
}
main {
  display: flex;
  margin: 5%;
}
span {
  margin: 0;
  padding: 0;
  color: red;
}
.desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seemore {
  border: 1px solid orange;
  background-color: orange;
  border-radius: 10px 10px;

  text-align: center;
}
.seemore:hover {
  border: 1px solid orange;
  background-color: black;
  border-radius: 10px 10px;
  color: white;
}
.slidercont {
  margin-top: 60px;
}

.imgslidercont {
  height: 50%;
}
@media screen and (max-width: 1500px) {
  main {
    display: flex;
    margin: 5%;
  }
  .prog {
    border: 1px solid orange;
    width: 460px;
    display: flex;

  }
  .mainbody {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
  }
.progone,
.progtwo,
.progthree {
  display: flex;
  flex-direction: column;
  font-family: "Courier New", Courier, monospace;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(18px);
          backdrop-filter: blur(18px);
  justify-content: space-between;
  margin-left: 3%;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
}


@media screen and (max-width: 573px) {
  main {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
  }
  .prog {
    width: auto;
    margin-bottom: 5%;
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: none;
    box-shadow: none;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .mainbody {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
  }
}




.control-panel {
    background-color:rgba(255, 255, 255, 0.5);
    color:black;
    text-align: center;
}

@media screen and (max-width: 573px) {
    .control-panel {
        background-color:rgba(255, 255, 255, 0.5);
        color:black;
        text-align: center;
        -webkit-transform: rotate(90deg) translate(100px);
                transform: rotate(90deg) translate(100px);
    
    }
  }
.memberinfo {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.contactUsMain {
  display: flex;
  justify-content: space-evenly;
}

.socialMedia {
  color:red;
  font-size: 30px;
  text-align: center;
  transition: ease-in-out;
  padding-left: 20px;
  transition-duration: 2s;
}
.socialMedia:hover {
  color:black;
}
.socialMediaList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 573px) {
  .contactUsMain {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .Mapbox {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
}

.founderpic {
  width: 400px;
  height: auto;
  border-radius: 50% 50%;
}
.founderpic > img {
  display: block;
  overflow: hidden;
  border-radius: 50% 50%;
}

.goalIcon{
  color:red;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
}
.goalIcon:hover{
  color:yellow;
}
.founderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.founderName {
  color: orange;
}
.contactuspage {
  margin: 5%;
}
.foundertitle {
  color: red;
}

.founderMessage {
  font-size: larger;
}

@media screen and (max-width: 573px) {
  .founderDiv {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .founderpic {
    width: 200px;
    height: 200px;
    border-radius: 50% 50%;
  }
  .founderpic > img {
    display: block;
    overflow: hidden;
    border-radius: 50% 50%;
  }
}

.programs {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 0.3fr 0.3fr 0.3fr;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  margin: 5%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  justify-content: center;
}
.description {
  grid-column-start: 2;
  grid-column-end: span col3-start;

  font-weight: bold;
  border-radius: 0% 0% 100% 100% / 44% 100% 100% 0%;
}
.sublist {
  padding-left: 25px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border-radius: 10px;
  border: 1px solid orange;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.applybutton {
  justify-self: end;
  outline: none;
  border: none;
  padding-top: 10px;
  padding-bottom: 13px;
  color: white;
  background-color: rgb(248, 2, 2);

  border-radius: 28% 23% 24% 23% / 17% 17% 15% 20%;
}
.applybutton:hover {
  color: black;
  background-color: rgb(248, 151, 151);
}

li {
  list-style: none;
  margin-bottom: 20px;
}
.dumbell {
  color: red;
}

.arrowdown {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrowup {
  color: red;
}

.sublistheading {
  color: orange;
}

@media screen and (max-width: 573px) {
  .programs {
    display: flex;
    flex-direction: column;
  }
}

.applysection {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 0.3fr 0.3fr ;
  justify-content: space-between;


}
.programdet,
.inputform {
  display: flex;
  flex-direction: column;

}
.programdet{
  width: 400px;
  margin-left: 15px;


}
.inputform {
  justify-self: center;
  
}
input {
  color: red;
  outline: none;

}
label {
  margin-top: 10px;
  margin-bottom: 10px;
  color: orange;
  font-family: "Old Standard TT", serif;
}
.mainApply{
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
 
}
h1 {
  font-family: "Old Standard TT", serif;
}

.programdetlist {
  color: red;
  border: none;
  font-family: "Old Standard TT", serif;
  font-weight: bold;
}
@media screen and (max-width: 573px) {
  .applysection {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
  }

}

.gallaryMain {
  position: relative;
  height: 100%;
  width: 100%;
}

.gallaryMain .gallary {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2vh;
  grid-auto-flow: dense;
}
.gallaryMain .gallary .gallarypics {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.9);
  border-radius: 2px;
}

.gallaryMain .gallary .gallarypics:first-child {
  grid-column-start: span 2;
  grid-row-start: span 3;
}
.gallaryMain .gallary .gallarypics:nth-child(2n + 3) {
  grid-row-start: span 2;
}
.gallaryMain .gallary .gallarypics:nth-child(4n + 5) {
  grid-column-start: span 2;
  grid-row-start: span 2;
}
.gallaryMain .gallary .gallarypics:nth-child(6n + 7) {
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(8n + 9) {
  grid-column-start: span 2;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(10n + 11) {
  grid-column-start: span 1;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(12n + 13) {
  grid-column-start: span 2;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-filter: brightness(0.5) grayscale(100);
          filter: brightness(0.5) grayscale(100);
  transition: 0.3s ease-in-out;
}

.gallaryMain .gallary .gallarypics:hover img {
  -webkit-filter: brightness(1) grayscale(0);
          filter: brightness(1) grayscale(0);
}
@media screen and (max-width: 573px) {
  .gallaryMain .gallary {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

