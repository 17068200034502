.gallaryMain {
  position: relative;
  height: 100%;
  width: 100%;
}

.gallaryMain .gallary {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2vh;
  grid-auto-flow: dense;
}
.gallaryMain .gallary .gallarypics {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.9);
  border-radius: 2px;
}

.gallaryMain .gallary .gallarypics:first-child {
  grid-column-start: span 2;
  grid-row-start: span 3;
}
.gallaryMain .gallary .gallarypics:nth-child(2n + 3) {
  grid-row-start: span 2;
}
.gallaryMain .gallary .gallarypics:nth-child(4n + 5) {
  grid-column-start: span 2;
  grid-row-start: span 2;
}
.gallaryMain .gallary .gallarypics:nth-child(6n + 7) {
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(8n + 9) {
  grid-column-start: span 2;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(10n + 11) {
  grid-column-start: span 1;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics:nth-child(12n + 13) {
  grid-column-start: span 2;
  grid-row-start: span 1;
}
.gallaryMain .gallary .gallarypics img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.5) grayscale(100);
  transition: 0.3s ease-in-out;
}

.gallaryMain .gallary .gallarypics:hover img {
  filter: brightness(1) grayscale(0);
}
@media screen and (max-width: 573px) {
  .gallaryMain .gallary {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
