footer {
  width: 50%;
  margin-left: 30%;
  /* border: 1px solid black; */
  text-align: center;
}
.track{
  border:1px solid orange;

}
.nextButton {
  color:red;
}
.slide {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
}
.slider {
  height: 200px;
  
  /* background: rgba(220, 199, 199, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */
}
.descriptions2 {
  display: -webkit-box;
  display: -ms-flexbox;
  justify-self: center;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/* .sliderimage {
  width: 100%;
  height: 100%;
} */
.sliderimage {
  width: 180px;
  height: auto;
  align-self: center;
  justify-self: center;
}
.sliderimage > img {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 573px) {
  footer {
    width: 100%;
    margin: 0%;
  }
  .sliderimage {
    width: 60%;
    height: 70%;
    border-radius: 50% 50%;
  }
  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
  }
  .slidertitle {
    order:1;
  }
  .descriptions2 {
    order: 2;
  }
}
