.contactUsMain {
  display: flex;
  justify-content: space-evenly;
}

.socialMedia {
  color:red;
  font-size: 30px;
  text-align: center;
  transition: ease-in-out;
  padding-left: 20px;
  transition-duration: 2s;
}
.socialMedia:hover {
  color:black;
}
.socialMediaList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 573px) {
  .contactUsMain {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .Mapbox {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
}
