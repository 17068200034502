.control-panel {
    background-color:rgba(255, 255, 255, 0.5);
    color:black;
    text-align: center;
}

@media screen and (max-width: 573px) {
    .control-panel {
        background-color:rgba(255, 255, 255, 0.5);
        color:black;
        text-align: center;
        transform: rotate(90deg) translate(100px);
    
    }
  }