.founderpic {
  width: 400px;
  height: auto;
  border-radius: 50% 50%;
}
.founderpic > img {
  display: block;
  overflow: hidden;
  border-radius: 50% 50%;
}

.goalIcon{
  color:red;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
}
.goalIcon:hover{
  color:yellow;
}
.founderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.founderName {
  color: orange;
}
.contactuspage {
  margin: 5%;
}
.foundertitle {
  color: red;
}

.founderMessage {
  font-size: larger;
}

@media screen and (max-width: 573px) {
  .founderDiv {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .founderpic {
    width: 200px;
    height: 200px;
    border-radius: 50% 50%;
  }
  .founderpic > img {
    display: block;
    overflow: hidden;
    border-radius: 50% 50%;
  }
}
