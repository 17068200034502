@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap");

body {
  margin: 0;
  padding: 0;
  padding: 0;
  position: relative;
}

.mainbody {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}

img {
  display: block;
  height: 100%;
  width: 100%;
}
