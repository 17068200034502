.programs {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 0.3fr 0.3fr 0.3fr;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  margin: 5%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  justify-content: center;
}
.description {
  grid-column-start: 2;
  grid-column-end: span col3-start;

  font-weight: bold;
  border-radius: 0% 0% 100% 100% / 44% 100% 100% 0%;
}
.sublist {
  padding-left: 25px;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border-radius: 10px;
  border: 1px solid orange;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.applybutton {
  justify-self: end;
  outline: none;
  border: none;
  padding-top: 10px;
  padding-bottom: 13px;
  color: white;
  background-color: rgb(248, 2, 2);

  border-radius: 28% 23% 24% 23% / 17% 17% 15% 20%;
}
.applybutton:hover {
  color: black;
  background-color: rgb(248, 151, 151);
}

li {
  list-style: none;
  margin-bottom: 20px;
}
.dumbell {
  color: red;
}

.arrowdown {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrowup {
  color: red;
}

.sublistheading {
  color: orange;
}

@media screen and (max-width: 573px) {
  .programs {
    display: flex;
    flex-direction: column;
  }
}
