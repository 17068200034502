.applysection {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 0.3fr 0.3fr ;
  justify-content: space-between;


}
.programdet,
.inputform {
  display: flex;
  flex-direction: column;

}
.programdet{
  width: 400px;
  margin-left: 15px;


}
.inputform {
  justify-self: center;
  
}
input {
  color: red;
  outline: none;

}
label {
  margin-top: 10px;
  margin-bottom: 10px;
  color: orange;
  font-family: "Old Standard TT", serif;
}
.mainApply{
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
 
}
h1 {
  font-family: "Old Standard TT", serif;
}

.programdetlist {
  color: red;
  border: none;
  font-family: "Old Standard TT", serif;
  font-weight: bold;
}
@media screen and (max-width: 573px) {
  .applysection {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
  }

}
