@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed&display=swap");

.humbermenuicon {
  display: none;
}
.navigation {
  display: block;
}
.selected {
  outline: none;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: orangered 1px solid;
}
.slogan {
  font-family: "Fira Sans Extra Condensed", sans-serif;
}
a {
  text-decoration: none;
  color: red;
  text-align: left;
}
.logoimg {
  width: 390px;
}
.logoimg > img {
  width: 100%;
  height: 100%;
}
.logoandslogongroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 573px) {
  .navigation {
    display: none;
  }
  .active {
    background: rgba(255, 44, 44, 0.767);
    -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 2;

    -webkit-animation: rotate-bottom 0.5s ease-out both;
    animation: rotate-bottom 0.5s ease-out both;
    display: flex;
    overflow: scroll;
    position: fixed;
    right: 25px;
    width: 25%;
    height: 65vh;
    border-radius: 43px 43px 43px 32px / 32px 32px 32px 43px;
  }
  .active > .navbar > a {
    color: white;
  }
  .active > .navbar {
    margin: 0;
    padding: 0;

    border: none;
    display: flex;

    flex-direction: column;
  }
  .humbermenuicon {
    border-radius: 50% 50%;
    border-color: black;
    background-color: red;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    color: whitesmoke;
    justify-content: center;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 4;
  }
  .selected {
    outline: none;
    border-bottom: 1px solid tomato;

    text-align: left;
    display: block;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-7-27 12:52:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation rotate-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes rotate-bottom {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
  }
  @keyframes rotate-bottom {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
    }
  }
}
